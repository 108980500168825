import { Inject } from '@nuxt/types/app'

declare module 'vue/types/vue' {
    interface Vue {
        $validationConstants: any
    }
}

declare module '@nuxt/types' {
    interface NuxtAppOptions {
        $validationConstants: any
    }

    interface Context {
        $validationConstants: any
    }
}

export default async ({ $axios }: any, inject: Inject) => {
    const validationConstants = await $axios.$get('/api/validations')
    inject('validationConstants', validationConstants)
}
