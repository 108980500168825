






import Vue from 'vue'

import { ExpandHTMLElement } from '@/interfaces/expandHTMLElement'

export default Vue.extend({
    name: 'ExpandTransition',
    methods: {
        beforeEnter(element: ExpandHTMLElement) {
            element.style.display = 'block'
            element.style.maxHeight = 'unset'
            element.beforeEnterOffsetHeight = element.offsetHeight
            element.style.display = 'unset'
            element.style.maxHeight = '0'
        },
        enter(element: ExpandHTMLElement) {
            element.style.maxHeight = element.beforeEnterOffsetHeight + 'px'
        },
    },
})
