export default async function ({
    $axios,
    route: {
        params: { quoteIdentifier: quoteGuid },
    },
    redirect,
}) {
    const quote = await $axios.$get('/api/quotes?guid=' + quoteGuid)
    if (quote.isComplete) {
        redirect('/complete')
    }
}
