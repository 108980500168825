// eslint-disable-next-line no-undef
import { useContext } from '@nuxtjs/composition-api'
import { format } from 'date-fns'
import Vue from 'vue'

Vue.filter('readableNumber', function (value: string) {
    return Number(value).toLocaleString('ZA', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })
})

Vue.filter('readableDate', function (value: string) {
    const { $formats } = useContext()
    return format(new Date(value), $formats.DateFormat)
})

Vue.filter('prettyBytes', function (value: number) {
    if (!isFinite(value) || value < 0) {
        return value
    }

    const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    // calculate value exponent
    const exponent = Math.min(Math.floor(Math.log(value) / Math.log(1000)), units.length - 1)
    const unit = units[exponent]

    // calculate and return unit value
    return (value / Math.pow(1000, exponent)).toFixed(2) + ' ' + unit
})
