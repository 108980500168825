










import Vue from 'vue'

export default Vue.extend({
    props: {
        styleClass: {
            type: String,
            default: '',
        },
    },
})
