import { render, staticRenderFns } from "./Loading.vue?vue&type=template&id=09035380&"
import script from "./Loading.vue?vue&type=script&lang=js&"
export * from "./Loading.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('D:/Agent/DevOps-2/_work/628/s/src/Sanlam.Easy.Signup.Ui/src/components/base/Loading.vue').default})
