




// required for the formatter
import 'cleave.js'
import 'cleave.js/dist/addons/cleave-phone.za'
import Vue from 'vue'

import InputBase from '@/components/base/inputs/InputBase.vue'

export default Vue.extend({
    name: 'InputPhone',
    components: { InputBase },
    data() {
        return {
            options: {
                phone: true,
                phoneRegionCode: 'ZA',
            },
        }
    },
})
