import { format, parse, parseISO } from 'date-fns'

export function emailFormatter(email: unknown): string {
    if (!email) return ''
    const parts = String(email).split('@')
    if (parts[1]) {
        parts[1] = parts[1].toLowerCase()
    }
    return parts.join('@')
}

export function formatDate(value: any, dateFormat: string) {
    const date = new Date(value).toISOString()
    const formattedDate = format(parseISO(date), dateFormat)

    return formattedDate
}

export function parseDate(value: any, dateFormat: string) {
    const parsed = parse(value, dateFormat, new Date())

    return parsed
}
