import 'sweetalert2/dist/sweetalert2.min.css'
import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'

Vue.use(VueSweetalert2, {
    position: 'center',
    icon: 'success',
    showConfirmButton: false,
    timer: 1500,
})
