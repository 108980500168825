//
//
//
//

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    components: {
        Loading,
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
        }
    },
    methods: {
        start() {
            this.isLoading = true
        },
        finish() {
            this.isLoading = false
        },
        set() {
            this.isLoading = true
            return this
        },
        fail() {
            return this
        },
    },
}
