











import Vue from 'vue'

import CookiePolicy from '@/components/CookiePolicy.vue'

export default Vue.extend({
    components: { CookiePolicy },
    data() {
        return {
            showMenuOverlay: false,
            showCookiePolicy: !this.$cookies.get('isCookiePolicyViewed'),
        }
    },
    watch: {
        showCookiePolicy(value) {
            this.$cookies.set('isCookiePolicyViewed', !value)
        },
    },
})
