

















































































import Vue from 'vue'
import { email, required } from 'vuelidate/lib/validators'

import { GOOGLE_ANALYTICS_EVENTS } from '@/constants/googleAnalyticsEvents'
import { emailFormatter } from '@/helpers/formatters'
import { phoneNumber } from '@/helpers/validators'

export default Vue.extend({
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        topic: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            letUsCallYou: {
                name: null,
                email: null,
                contactNumber: null,
                message: null,
            },
        }
    },
    computed: {
        nameValidationErrors(): string[] {
            const errors: string[] = []
            if (!this.$v.letUsCallYou.name?.$dirty) {
                return errors
            }
            !this.$v.letUsCallYou.name?.required && errors.push('Please enter your name.')
            return errors
        },
        emailValidationErrors(): string[] {
            const errors: string[] = []
            if (!this.$v.letUsCallYou.email?.$dirty) {
                return errors
            }
            !this.$v.letUsCallYou.email?.email && errors.push('Please enter a valid email address.')
            !this.$v.letUsCallYou.email?.required && errors.push('Please enter an email address.')
            return errors
        },
        contactNumberValidationErrors(): string[] {
            const errors: string[] = []
            if (!this.$v.letUsCallYou.contactNumber?.$dirty) {
                return errors
            }
            !this.$v.letUsCallYou.contactNumber?.required && errors.push('Please enter a contact number.')
            if (!this.$v.letUsCallYou.contactNumber?.phoneNumber || !this.$v.letUsCallYou.contactNumber?.maxLength) {
                errors.push('Please enter a valid contact number')
            }

            return errors
        },
        messageValidationErrors(): string[] {
            const errors: string[] = []
            if (!this.$v.letUsCallYou.message?.$dirty) {
                return errors
            }
            if (!this.$v.letUsCallYou.message?.maxLength) {
                errors.push(
                    `The maximum character length has been exceeded (${this.$validationConstants.MaximumMessageLength} characters).`,
                )
            }

            return errors
        },
    },
    methods: {
        async createLead() {
            this.$v.$touch()

            if (this.$v.$invalid) {
                return
            }

            try {
                await this.$axios.$post('/api/leads', {
                    name: this.letUsCallYou.name,
                    email: this.letUsCallYou.email,
                    contactNumber: this.letUsCallYou.contactNumber,
                    section: this.$route.path,
                    topic: this.topic,
                    message: this.letUsCallYou.message,
                })
                await this.$axios.$post('/api/emails/call-me-back', {
                    name: this.letUsCallYou.name,
                    email: this.letUsCallYou.email,
                    contactNumber: this.letUsCallYou.contactNumber,
                    message: this.letUsCallYou.message,
                    section: this.$route.path,
                    topic: this.topic,
                })
            } catch ({ response }) {
                // eslint-disable-next-line no-console
                console.error(response)
            }
            this.$swal.fire({
                title: "Thank you for your enquiry. We'll get back to you.",
            })
            this.$gtm.push({ event: GOOGLE_ANALYTICS_EVENTS.GA_EVENT_STEP_10 })
            this.closeModal()
        },
        closeModal() {
            this.$v.$reset()
            this.reset()
            this.$emit('update:show', false)
        },
        reset() {
            this.letUsCallYou.name = null
            this.letUsCallYou.email = null
            this.letUsCallYou.contactNumber = null
            this.letUsCallYou.message = null
        },
    },
    validations: {
        letUsCallYou: {
            name: {
                required,
            },
            email: {
                required,
                // @ts-ignore
                email: val => email(emailFormatter(val)),
            },
            contactNumber: {
                required,
                phoneNumber,
                maxLength(this: any, value) {
                    return !value || this.$validationConstants.MaximumContactNumberLength >= value.length
                },
            },
            message: {
                maxLength(this: any, value) {
                    return !value || this.$validationConstants.MaximumMessageLength >= value.length
                },
            },
        },
    },
})
