import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=dfe0a546&"
import script from "./Footer.vue?vue&type=script&lang=ts&"
export * from "./Footer.vue?vue&type=script&lang=ts&"
import style0 from "./Footer.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Accordion: require('D:/Agent/DevOps-2/_work/628/s/src/Sanlam.Easy.Signup.Ui/src/components/base/Accordion.vue').default,Footer: require('D:/Agent/DevOps-2/_work/628/s/src/Sanlam.Easy.Signup.Ui/src/components/navigation/Footer.vue').default})
