



















import Vue from 'vue'

export default Vue.extend({
    name: 'Accordion',
    props: {
        blocks: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            expandedBlockIndex: -1,
        }
    },
    methods: {
        updateAccordionBlockOpen(index: number) {
            this.expandedBlockIndex = this.expandedBlockIndex !== index ? index : -1
        },
        isAccordionBlockExpanded(index: number) {
            return this.expandedBlockIndex === index
        },
    },
})
