export const GOOGLE_ANALYTICS_EVENTS = {
    GA_EVENT_STEP_1: 'Quote Submitted',
    GA_EVENT_STEP_2: 'Plan Selected',
    GA_EVENT_STEP_3_OPTION_1: 'Employee Manual Option Selected',
    GA_EVENT_STEP_3_OPTION_2: 'Employee Upload Option Selected',
    GA_EVENT_STEP_3_A: 'Employees Added Manually',
    GA_EVENT_STEP_3_B: 'Employees Uploaded',
    GA_EVENT_STEP_4: 'Quote Reviewed',
    GA_EVENT_STEP_5: 'Application Confirmed',
    GA_EVENT_STEP_6: 'Add Intermediary',
    GA_EVENT_STEP_7: 'Payment Setup Completed',
    GA_EVENT_STEP_8: 'Supporting Documents Uploaded',
    GA_EVENT_STEP_9: 'Digital Signature Requested',
    GA_EVENT_STEP_10: 'Call Me Back',
}
