import { isValid } from 'date-fns'
// @ts-ignore
import { validateIdNumber } from 'south-african-id-validator'
import { helpers } from 'vuelidate/lib/validators'

export const phoneNumber = helpers.regex('phoneNumberWithCountryCode', /^((\+27|0)[0-9]{9})/)
export const passportNumber = helpers.regex('passportNumber', /^(?!^0+$)[a-zA-Z0-9]{3,20}$/)

export function southAfricanIdentificationNumber(identificationNumber: any) {
    return validateIdNumber(identificationNumber)
}

export function isNullOrEmpty(value: any) {
    return value === '' || value == null
}

export function minimumAge(value: any, parameter: number): boolean {
    return getAge(value) >= parameter
}

export function maximumAge(value: any, parameter: number): boolean {
    return getAge(value) <= parameter
}

export function startDate(value: any, startDate: Date): boolean {
    const date = new Date(value)
    return date > startDate
}

export function endDate(value: any, endDate: Date): boolean {
    const date = new Date(value)
    return date < endDate
}

export function isValidDate(value: any) {
    let result = false

    if (isValid(value)) {
        result = true
    }

    return result
}

function getAge(value: any) {
    const today = new Date()
    const birthDate = new Date(value)
    let age = today.getFullYear() - birthDate.getFullYear()
    const month = today.getMonth() - birthDate.getMonth()
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
}
