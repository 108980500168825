














import Vue from 'vue'

export default Vue.extend({
    name: 'CookiePolicy',
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
})
