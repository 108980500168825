import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=795b2f54&"
import script from "./Header.vue?vue&type=script&lang=ts&"
export * from "./Header.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GetStartedButton: require('D:/Agent/DevOps-2/_work/628/s/src/Sanlam.Easy.Signup.Ui/src/components/base/GetStartedButton.vue').default,LetUsCallYouModal: require('D:/Agent/DevOps-2/_work/628/s/src/Sanlam.Easy.Signup.Ui/src/components/modals/LetUsCallYouModal.vue').default,Header: require('D:/Agent/DevOps-2/_work/628/s/src/Sanlam.Easy.Signup.Ui/src/components/navigation/Header.vue').default})
