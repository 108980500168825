

































































import Vue from 'vue'

export default Vue.extend({
    props: {
        menus: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        showMenu: false,
        showModal: false,
        expandedMenuIndex: -1,
        showGetStartedButton: false,
    }),
    watch: {
        $route() {
            this.toggleGetStartedButton()
        },
    },
    beforeMount() {
        window.addEventListener('resize', this.onWindowResize)
        this.toggleGetStartedButton()
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize)
    },
    methods: {
        updateShowMenu(visible: boolean) {
            this.expandedMenuIndex = -1

            this.showMenu = visible
            this.$emit('update:show-menu', this.showMenu)
        },
        updateExpandedMenu(index: number) {
            this.expandedMenuIndex = this.expandedMenuIndex !== index ? index : -1
        },
        isMenuExpanded(index: number) {
            return this.expandedMenuIndex === index
        },
        onWindowResize() {
            if (window.innerWidth > 1199) {
                this.updateShowMenu(false)
            }
        },
        toggleGetStartedButton() {
            this.showGetStartedButton = this.$route.name === 'what-we-offer'
        },
    },
})
