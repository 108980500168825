




import Cleave from 'cleave.js'
import Vue from 'vue'

interface Data {
    cleave: undefined | Cleave
    currentValue: string
    currentRawValue: string | null
}

export default Vue.extend({
    name: 'InputBase',
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        options: {
            type: Object,
            required: true,
        },
    },
    data(): Data {
        return {
            cleave: undefined,
            currentValue: this.value?.toString(),
            currentRawValue: '',
        }
    },
    watch: {
        value(value) {
            this.setCleaveRawValue(value?.toString())

            if (value == null) {
                this.currentRawValue = null
            }
        },
    },
    mounted() {
        const input = this.$refs.input as HTMLInputElement

        this.cleave = new Cleave(input, {
            ...this.options,
            onValueChanged: this.onValueChanged.bind(this),
        })

        this.setCleaveRawValue(this.value?.toString())
    },
    beforeDestroy() {
        if (this.cleave) {
            this.cleave.destroy()
            this.cleave = undefined
        }
    },
    methods: {
        onValueChanged({ target: { rawValue, value } }: { target: { rawValue: string; value: string } }) {
            this.$nextTick(() => {
                // prevent initial <empty string> $emit
                if (this.currentRawValue === rawValue) {
                    return
                }

                // prevent initial null emits
                if (this.options.date) {
                    this.$emit('input', value)
                } else if (this.currentRawValue != null) {
                    this.$emit('input', rawValue)
                }

                this.currentValue = value
                this.currentRawValue = rawValue
            })
        },
        setCleaveRawValue(value: string) {
            this.cleave?.setRawValue(value)
        },
    },
})
