import { Inject } from '@nuxt/types/app'

declare module 'vue/types/vue' {
    interface Vue {
        $applicationInformation: any
        $formats: any
    }
}

declare module '@nuxt/types' {
    interface NuxtAppOptions {
        $applicationInformation: any
        $formats: any
    }

    interface Context {
        $applicationInformation: any
        $formats: any
    }
}

export default async ({ $axios }: any, inject: Inject) => {
    const applicationInformation = await $axios.$get('/api/constants/application-information')
    const formats = await $axios.$get('/api/constants/formats')
    inject('applicationInformation', applicationInformation)
    inject('formats', formats)
}
