

























































































































































import Accordion from '../base/Accordion.vue';
import Vue from 'vue';

export default {
    components: {
        Accordion
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            blocks: [
                {
                    heading: 'About',
                    showMore: false,
                    content: [
                        [
                            {
                                text: 'Our strategy',
                                url: 'https://www.sanlam.co.za/about/Pages/default.aspx?&_ga=2.140817787.895164205.1689756231-135497700.1677488794&_gl=1*1tabcbw*_ga*MTM1NDk3NzAwLjE2Nzc0ODg3OTQ.*_ga_3NHV088ZTX*MTY4OTc1NjIzMS4yMC4wLjE2ODk3NTYyMzEuNjAuMC4w#strategy'
                            },
                            {
                                text: 'Investors Relations',
                                url: 'https://www.sanlam.com/index'
                            },
                            {
                                text: 'B-BBEE Certificate',
                                url: 'https://www.sanlam.com/transformation'
                            },
                            {
                                text: 'Sanlam at a glance',
                                url: 'https://www.sanlam.com/sanlam-at-a-glance'
                            },
                        ],
                        [
                            {
                                text: 'Leadership',
                                url: 'https://www.sanlam.com/leadership'
                            },
                            {
                                text: 'Our History',
                                url: 'https://www.sanlam.com/history'
                            },
                            {
                                text: 'Transformation',
                                url: 'https://www.sanlam.com/transformation'
                            },
                            {
                                text: 'Careers',
                                url: 'https://www.sanlam.co.za/careers/Pages/default.aspx?_ga=2.110908108.895164205.1689756231-135497700.1677488794&_gl=1*wx0dgs*_ga*MTM1NDk3NzAwLjE2Nzc0ODg3OTQ.*_ga_3NHV088ZTX*MTY4OTc1NjIzMS4yMC4xLjE2ODk3NTY1NTguNjAuMC4w'
                            },
                        ],
                        [
                            {
                                text: 'Brand Showreel',
                                url: 'https://www.sanlam.co.za/brandshowreel/Pages/default.aspx'
                            },
                            {
                                text: 'Sponsorships',
                                url: 'https://www.sanlam.co.za/sponsorships/Pages/default.aspx?_ga=2.105692746.895164205.1689756231-135497700.1677488794&_gl=1*11pwr82*_ga*MTM1NDk3NzAwLjE2Nzc0ODg3OTQ.*_ga_3NHV088ZTX*MTY4OTc1NjIzMS4yMC4xLjE2ODk3NTY1NTguNjAuMC4w'
                            },
                            {
                                text: 'Sanlam Bluestar',
                                url: 'https://www.sanlam.co.za/bluestar/Pages/default.aspx?_ga=2.206745946.895164205.1689756231-135497700.1677488794&_gl=1*c4yy7w*_ga*MTM1NDk3NzAwLjE2Nzc0ODg3OTQ.*_ga_3NHV088ZTX*MTY4OTc1NjIzMS4yMC4xLjE2ODk3NTY1NTguNjAuMC4w'
                            }
                        ]
                    ],
                },
                {
                    heading: 'Legal',
                    showMore: false,
                    content: [
                        [
                            {
                                text: 'Terms of Use',
                                url: 'https://www.sanlam.com/terms-of-use'
                            },
                            {
                                text: 'Retirement Funds',
                                url: 'https://www.sanlam.co.za/retirementfunds/Pages/default.aspx?_ga=2.202084696.895164205.1689756231-135497700.1677488794&_gl=1*1v4e9iu*_ga*MTM1NDk3NzAwLjE2Nzc0ODg3OTQ.*_ga_3NHV088ZTX*MTY4OTc1NjIzMS4yMC4xLjE2ODk3NTcxNzYuNjAuMC4w'
                            },
                            {
                                text: 'Plan Descriptions',
                                url: 'https://www.sanlam.co.za/plandescriptions/Pages/default.aspx?_ga=2.69374425.895164205.1689756231-135497700.1677488794&_gl=1*1aoseru*_ga*MTM1NDk3NzAwLjE2Nzc0ODg3OTQ.*_ga_3NHV088ZTX*MTY4OTc1NjIzMS4yMC4xLjE2ODk3NTcxNzYuNjAuMC4w'
                            },
                            {
                                text: 'Privacy Notice',
                                url: 'https://www.sanlam.com/sanlams-privacy-policy'
                            },
                        ],
                        [
                            {
                                text: 'Financial Advisory and Intermediary Services Act (FAIS)',
                                url: 'https://www.sanlam.com/financial-advisory-and-intermediary-services-act'
                            },
                            {
                                text: 'Principles and Practices of Financial Management (PPFM)',
                                url: 'https://www.sanlam.com/principles-and-practices-of-financial-management'
                            },
                            {
                                text: 'Promotion of Access to Information Act (POPIA)',
                                url: 'https://www.sanlam.com/promotion-of-access-to-information-act'
                            },
                            {
                                text: 'Conflict of Interest Policies in terms of FAIS',
                                url: 'https://www.sanlam.com/conflicts-of-interest-policies-in-terms-of-FAIS'
                            }
                        ],
                    ]
                },
            ],
            expanded: {}
        }
    },
    methods: {
        toggleShowMore(this: Vue, index: number) {
            this.$data.blocks[index].showMore = !this.$data.blocks[index].showMore;
        }
    }
}
